import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getBlogPosts } from '../utils/blogLoader';
import ReactMarkdown from 'react-markdown';
import { Mail } from 'lucide-react';

const TabContent = ({ activeTab, setActiveTab }) => {
  const blogPosts = getBlogPosts();

  return (
    <div className="section">
      {/* Tab Navigation */}
      <div className="flex space-x-4 mb-6">
        <button
          className={`py-2 px-6 font-medium text-sm transition-colors duration-200 rounded-full ${
            activeTab === 'product'
              ? 'bg-burgundy text-white shadow-md'
              : 'bg-white text-burgundy hover:bg-peach hover:text-white border border-burgundy'
          }`}
          onClick={() => setActiveTab('product')}
        >
          Product
        </button>
        <button
          className={`py-2 px-6 font-medium text-sm transition-colors duration-200 rounded-full ${
            activeTab === 'team'
              ? 'bg-burgundy text-white shadow-md'
              : 'bg-white text-burgundy hover:bg-peach hover:text-white border border-burgundy'
          }`}
          onClick={() => setActiveTab('team')}
        >
          Team
        </button>
        <button
          className={`py-2 px-6 font-medium text-sm transition-colors duration-200 rounded-full ${
            activeTab === 'blog'
              ? 'bg-burgundy text-white shadow-md'
              : 'bg-white text-burgundy hover:bg-peach hover:text-white border border-burgundy'
          }`}
          onClick={() => setActiveTab('blog')}
        >
          Blog
        </button>
        <div className="flex-grow"></div>
        <button
          className="py-2 px-6 font-medium text-sm transition-colors duration-200 rounded-full bg-navy text-white hover:bg-blue-light hover:text-black shadow-md"
          onClick={() => window.location.href = 'mailto:info@navis-bio.com'}
        >
          Get in touch
        </button>
      </div>      
      <div className="border-b border-black mb-6"></div>

      {/* Tab Content */}
      <div className="prose max-w-none">
        {activeTab === 'product' && (
          <div>
            <h2 className="text-3xl font-semibold text-black mb-4">Building the data foundation for translational research</h2>
            <div className="flex flex-wrap items-end justify-strech gap-x-5 gap-y-4 mt-8">
              {[
                {
                  text: "Easy interfaces to public databases",
                  content: (
                    <>
                      <p>Integrate insights from clinical-trials.gov, OpenFDA, Signor, Chembl, OpenTargets, etc.</p>
                      <p>See also our{' '}
                        <a href="https://navis-bio.com/?tab=blog#MCP-ctgov" target="_blank" rel="noopener noreferrer">
                          open source interface to clinicaltrials.gov
                        </a>
                        {' '} in the Claude AI chat
                      </p>
                    </>
                  ),
                  color: "border-peach",
                  bg: "bg-peach",
                  size: "w-[600px]"
                },
                {
                  text: "Easy integration with your own data and 3rd party data",
                  content: <p>Simple API access to our proprietary database</p>,
                  color: "border-greenlight",
                  bg: "bg-greenlight",
                  size: "w-[400px]"
                },
                {
                  text: "Curated datasets, filling gaps in public datasets",
                  content: (
                    <>
                      <p>Always up to date with evolving trial landscape</p>
                      <p>
                        Rapid insights into targets and compounds presented at conferences (
                        <a href="https://navis-bio.com/?tab=blog#ASH-dashboard" target="_blank" rel="noopener noreferrer">
                          small demo to visualise from industry submissions to ASH 2024
                        </a>)
                      </p>
                      <p>Higher granularity of information, e.g. on biomarkers or mechanism of action</p>
                    </>
                  ),
                  color: "border-graylight",
                  bg: "bg-graylight",
                  size: "w-[1020px]"
                },
              ].map((item, index) => (
                <div 
                  key={index} 
                  className={`pt-4 pb-4 p-6 border-8 rounded-3xl ${item.color} ${item.bg} ${item.size} hover:shadow-lg transition-shadow`}
                >
                  <h3 className="text-black text-2xl mb-4">{item.text}</h3>
                  <div className="text-black text-md">{item.content}</div>
                </div>
              ))}
            </div>


              <h2 className="text-3xl font-semibold text-black mb-4 mt-12">Applications</h2>
              <div className="flex divide-x divide-gray-300 mt-8">
                {[
                  {
                    title: "Target assessment",
                    description: "Leverage data-driven insights for comprehensive target evaluation"
                  },
                  {
                    title: "Prediction of future Standard-of-care", 
                    description: "Use AI-driven predictions to extrapolate from current trends"
                  },
                  {
                    title: "Optimization of trial inclusion criteria",
                    description: "Enhance trial design with data-backed participant selection"
                  },
                  {
                    title: "Tracking competitor activities and new research findings",
                    description: "Stay informed with comprehensive market intelligence"
                  }
                ].map((item, index) => (
                  <div key={index} className="flex-1 px-6 first:pl-0 last:pr-0">
                    <h3 className="font-semibold text-lg text-black mb-2">{item.title}</h3>
                    <p className="text-gray-600 text-sm">{item.description}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          
        {activeTab === 'team' && (
          <div className="space-y-8">
            <div>
              <h2 className="text-3xl font-semibold text-black mb-4">Company</h2>
              <p className="text-black leading-relaxed">
              As AI is transforming the way we design drugs, 5 years from now it will be easier than ever to design and make a drug. The real question will be: ‘Drug against what?’ This will put ever more focus on translational questions, such as identifying the right therapeutic target, the right patient population, the right biomarkers, etc. and moving fast in every such decision. At Navis Bio we are building a comprehensive knowledge base, analytics tools, and AI methods for translational research.
              </p>
            </div>
            
            <div>
              <h2 className="text-3xl font-semibold text-black mb-4">Team</h2>

              <div className="mt-6">
                <div className="grid grid-cols-2 gap-0 max-w-lg">
                  <div className="space-y-0">
                    <img src="/Nives.jpg" alt="Nives Rombini" className="w-32 h-32 rounded-full mb-0" />
                    <div className="flex items-center space-x-2">
                      <h3 className="text-xl font-semibold text-black">Nives Rombini</h3>
                      <a href="https://www.linkedin.com/in/nives-rombini/" target="_blank" rel="noopener noreferrer">
                        <img src="/LI-In.png" alt="LinkedIn" className="w-5 h-4" />
                      </a>
                    </div>
                  </div>
                  
                  <div className="space-y-0">
                    <img src="/Jonas.jpg" alt="Jonas Walheim" className="w-32 h-32 rounded-full mb-0" />
                    <div className="flex items-center space-x-2">
                      <h3 className="text-xl font-semibold text-black">Jonas Walheim, PhD</h3>
                      <a href="https://www.linkedin.com/in/jonas-walheim-441b0284/" target="_blank" rel="noopener noreferrer">
                        <img src="/LI-In.png" alt="LinkedIn" className="w-5 h-4" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'blog' && (
          <div className="space-y-8">
            {blogPosts.map((post, index) => (
              <div 
                key={post.slug} 
                id={post.slug} 
                className={`py-6 ${index !== 0 ? 'border-t-2 border-orange' : ''}`}
              >
                <a 
                  href={`#${post.slug}`} 
                  className="hover:text-burgundy transition-colors duration-200 no-underline"
                >
                  <h2 className="text-3xl font-semibold mb-2">{post.title}</h2>
                </a>
                <p className="text-sm text-gray-500 mb-4">
                  {new Date(post.date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </p>
                <div className="prose max-w-none">
                  {post.isJsx ? (
                    <post.Content />
                  ) : (
                    <ReactMarkdown>{post.content}</ReactMarkdown>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TabContent;
